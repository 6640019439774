<template>
  <iframe class="win" :src="http"/>
</template>
<script>
//引入依赖包
import store from "@/store";
import storage from "store";
import {ACCESS_TOKEN} from "@/store/mutation-types";
const publicConfig = store.getters.publicConfig
const userInfo = store.getters.userInfo
const token = storage.get(ACCESS_TOKEN)
export default {
  name: "staging",
  data() {
    return {
      http: publicConfig.BASE_HTTP + 'sheet?store_id=' + userInfo.store_id+'&ACCESS-TOKEN='+token,
    };
  },
  created() {},
  methods: {}
}
</script>
<style scoped lang="less">
.win {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}
</style>
